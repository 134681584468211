<template>
  <v-card
    style="padding: 10px 20px 20px 20px; position: relative"
    class="text-center"
  >
    <v-btn
      icon
      @click="closeDialog"
      style="position: absolute; top: 10px; right: 25px; z-index: 99"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row no-gutters>
      <v-col md="12" style="padding: 0">
        <v-card-title>
          <v-spacer></v-spacer>DETAIL PPH21<v-spacer></v-spacer>
        </v-card-title>

        <v-card-text v-if="detail !== null" style="margin: 10px 0">
          <v-row no-gutters>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="
                  monthName(detail.detail_pph21.period_month) +
                  ' ' +
                  detail.detail_pph21.period_month
                "
                label="Periode"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="'Rp. ' + priceConvert(detail.detail_pph21.bruto)"
                label="Penghasilan Bruto"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="'Rp. ' + priceConvert(detail.detail_pph21.pph21)"
                label="Pph21"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="
                  'Rp. ' +
                  priceConvert(detail.detail_pph21.pph21_dipotong_november)
                "
                label="Pph21 Dipotong November"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="
                  'Rp. ' +
                  priceConvert(detail.detail_pph21.pph21_terhutang_setahun)
                "
                label="Pph21 Dipotong Setahun"
                readonly
              />
            </v-col>
            <v-col md="4" style="padding: 0 15px 0 0" />
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="
                  detail.employee.ptkp_status !== null
                    ? detail.employee.ptkp_status.name.slice(5, 9)
                    : '-'
                "
                label="Kode PTKP"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="'Rp. ' + priceConvert(detail.detail_pph21.ptkp_fee)"
                label="Biaya PTKP"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="'Rp. ' + priceConvert(detail.detail_pph21.pkp)"
                label="PKP"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="
                  'Rp. ' + priceConvert(detail.detail_pph21.biaya_jabatan)
                "
                label="Biaya Jabatan"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="
                  'Rp. ' + priceConvert(detail.detail_pph21.iuran_pensiun)
                "
                label="Iuran Pensiun"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="'Rp. ' + priceConvert(detail.detail_pph21.neto_setahun)"
                label="Neto Setahun"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="terType(detail.detail_pph21.ter_type_id)"
                label="Jenis TER"
                readonly
              />
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 15px 0 0">
              <v-text-field
                :value="Number(detail.detail_pph21.ter_percentage) + '%'"
                label="Persentase TER"
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-else
          class="d-flex justify-center align-center"
          style="height: 40vh; margin-top: 10px"
        >
          <p style="padding: 0">TIDAK DITEMUKAN</p>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ['detail'],
  data() {
    return {
      penerima_penghasilan: '-',
      no_identitas: '-',
      no_npwp: '-',
      facility: 'N'
    }
  },
  methods: {
    getCutOffDate() {
      console.log('tgl pemotongan>>>>', this.detail)
      const period =
        this.detail.period_year + '-' + this.detail.period_month + '-' + '30'
      const d = new Date(period)
      // const year = d.getFullYear()
      // const month = d.getMonth()

      return this.convertDate(d).slice(0, 9)
    },
    getPenerimaPenghasilan() {
      if (
        this.detail.employee.npwp_no !== null &&
        this.detail.employee.npwp_no !== '' &&
        this.detail.employee.npwp_no !== '-'
      ) {
        this.penerima_penghasilan = 'NPWP'
        if (this.detail.employee.npwp_no !== null) {
          this.no_npwp = this.detail.employee.npwp_no.replace(/[^\w\s]/gi, '')
        }
      } else {
        if (
          this.detail.employee.no_identitas !== null &&
          this.detail.employee.no_identitas !== '' &&
          this.detail.employee.no_identitas !== '-'
        ) {
          this.penerima_penghasilan = 'NIK'
          if (this.detail.employee.no_identitas !== null) {
            this.no_identitas = this.detail.employee.no_identitas.replace(
              /[^\w\s]/gi,
              ''
            )
          }
        }
      }
    },
    getFacility() {
      if (this.detail.employee.pph_facility_id !== null) {
        if (this.detail.employee.pph_facility_id == 1) {
          this.facility = 'SKB'
        }
        if (this.detail.employee.pph_facility_id == 2) {
          this.facility = 'DTP'
        }
      } else {
        this.facility = 'N'
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = Number(xVal)
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    closeDialog() {
      setTimeout(() => {
        this.$emit('closePph21Dialog')
      }, 300)
    },
    terType(val) {
      if (val !== null) {
        switch (val) {
          case 1:
            return 'TER A'
          case 2:
            return 'TER B'
          case 3:
            return 'TER C'
        }
      } else {
        return '-'
      }
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MAI'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    }
  }
}
</script>

<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-card
            tile
            flat
            style="
              border-bottom: 2px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: sticky;
              top: 60px;
              background: white;
              z-index: 5;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              "
            >
              <v-toolbar-title class="overline">
                <p
                  :style="`font-size:${
                    wWidth > 780 ? '20px' : '3vw'
                  }; margin: 0;`"
                >
                  SLIP GAJI KARYAWAN
                  <v-tooltip bottom color="grey">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        text
                        icon
                        color="grey darken-2"
                        @click="getDetail"
                      >
                        <v-icon small>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>refresh page</span>
                  </v-tooltip>
                </p>
              </v-toolbar-title>
            </div>
            <div>
              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="black"
                class="black--text font-weight-bold ma-2"
                style="font-size: 12px"
                @click="close"
              >
                <v-icon small>mdi-chevron-left</v-icon>
                Kembali
              </v-btn>
            </div>
          </v-card>

          <v-card
            v-if="detail !== null"
            elevation="0"
            style="margin: auto; padding: 0; width: 720px"
          >
            <v-form
              ref="entryForm"
              @submit.prevent="submitComponent()"
              lazy-validation
              :disabled="loading"
            >
              <v-row style="padding: 15px; border: 2px solid black" no-gutters>
                <v-col cols="12" style="padding: 0">
                  <v-row>
                    <v-col cols="4" style="padding: 0">
                      <div
                        style="
                          font-size: 12px;
                          font-weight: bolder;
                          text-align: center;
                        "
                      >
                        <p style="margin: 0">PT SANQUA MULTI INTERNASIONAL</p>
                        <p style="margin: 5px 0 0 0">
                          JL. Bintaro Permai Raya No. 26, RT 12 - RW 009
                          Kel.Bintaro Kec. Pesanggrahan
                        </p>
                        <p style="margin: 5px 0 0 0">Pribadi dan Rahasia</p>
                        <p style="margin: 5px 0 0 0">Slip Gaji Karyawan</p>
                      </div>
                    </v-col>
                    <v-col cols="4" style="padding: 0 0 0 15px">
                      <div style="font-size: 12px; text-align: left">
                        <p style="margin: 0">NIP</p>
                        <p
                          class="d-flex justify-start align-end"
                          style="margin-top: 5px; height: 27px"
                        >
                          PERIODE
                        </p>
                        <p style="margin: 5px 0 0 0">NAMA KARYAWAN</p>
                        <p style="margin: 5px 0 0 0">JABATAN</p>
                        <p style="margin: 5px 0 0 0">DEPARTMENT</p>
                      </div>
                    </v-col>
                    <v-col cols="4" style="padding: 0">
                      <div style="font-size: 12px; text-align: left">
                        <p style="margin: 0">{{ detail.employee.nik }}</p>
                        <p
                          class="d-flex justify-start align-end"
                          style="
                            margin-top: 5px;
                            height: 27px;
                            text-transform: capitalize;
                          "
                        >
                          {{ monthName(detail.period_month) }}
                          {{ detail.period_year }}
                        </p>
                        <p style="margin: 5px 0 0 0">
                          {{ detail.employee.name.toUpperCase() }}
                        </p>
                        <p style="margin: 5px 0 0 0">
                          {{ detail.employee.level.name.toUpperCase() }}
                        </p>
                        <p style="margin: 5px 0 0 0">
                          {{ detail.department.name.toUpperCase() }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                style="padding: 3px; border: 2px solid black; border-top: none"
                no-gutters
              >
                <v-col cols="12" style="padding: 0">
                  <v-row
                    no-gutters
                    style="font-size: 12px; font-weight: bolder"
                  >
                    <v-col cols="4">
                      <p style="margin: 0">KETERANGAN</p>
                    </v-col>
                    <v-col cols="2">Jumlah</v-col>
                    <v-col cols="4">Premi BPJS Perusahaan</v-col>
                    <v-col cols="2">Jumlah</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Gaji Pokok</p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.gaji_pokok) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px">
                      Ketenagakerjaan
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(detail.bpjs_tk_comp)
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Rapel</p>
                    </v-col>
                    <!-- rapel blm ada di result -->
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.rapel) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px">
                      Jaminan Pensiun
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(detail.bpjs_jp_comp)
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Lembur</p>
                    </v-col>
                    <v-col
                      @click="showOvertime()"
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px; cursor: pointer; color: blue"
                      >{{ priceConvert(detail.lembur) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px">
                      Jaminan Kesehatan
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(detail.bpjs_k_comp)
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Lain-lain</p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                    >
                      {{ priceConvert(detail.lain_lain) }}
                    </v-col>
                    <v-col cols="4"> </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" style="padding: 0; margin-top: 15px">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="6" style="font-weight: bolder">
                      <p style="margin: 0">Tunjangan</p>
                    </v-col>
                    <v-col cols="6" style="font-weight: bolder">
                      <p style="margin: 0">Premi BPJS Karyawan</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Jabatan</p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.tunjangan_jabatan) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px">
                      Ketenagakerjaan
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(detail.bpjs_tk_emp)
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Penempatan</p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.tunjangan_penempatan) }}</v-col
                    >

                    <v-col cols="4" style="padding-left: 10px">
                      Jaminan Pensiun
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(detail.bpjs_jp_emp)
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Perumahan</p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.tunjangan_perumahan) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px">
                      Jaminan Kesehatan
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(detail.bpjs_k_emp)
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">pph21</p>
                    </v-col>
                    <!-- blm ada di detail -->
                    <v-col
                      @click="showPph21"
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px; cursor: pointer; color: blue"
                      >{{ priceConvert(detail.pph21) }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4" class="d-flex align-end">
                      <p style="margin: 0; padding-left: 10px; font-size: 11px">
                        THR
                      </p>
                    </v-col>
                    <!-- blm ada di detail -->
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.thr) }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">Bonus</p>
                    </v-col>
                    <!-- blm ada di detail -->
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.bonus) }}</v-col
                    >
                  </v-row>
                </v-col>

                <v-col cols="12" style="padding: 0; margin-top: 15px">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="6" style="font-weight: bolder">
                      <p style="margin: 0">Premi BPJS Perusahaan</p>
                    </v-col>
                    <v-col cols="6" style="font-weight: bolder">
                      <p style="margin: 0">Potongan Lain-lain</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">
                        Ketenagakerjaan
                      </p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.bpjs_tk_comp) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px"> pph21 </v-col>

                    <v-col
                      @click="showPph21"
                      cols="2"
                      class="d-flex justify-end"
                      style="cursor: pointer; color: blue"
                      >{{ priceConvert(detail.pph21) }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">
                        Jaminan Pensiun
                      </p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.bpjs_jp_comp) }}</v-col
                    >
                    <v-col cols="4" style="padding-left: 10px"> Absensi </v-col>
                    <v-col
                      @click="showAbsence()"
                      cols="2"
                      class="d-flex justify-end"
                      style="cursor: pointer; color: blue"
                      >{{ priceConvert(detail.potongan_absensi) }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4">
                      <p style="margin: 0; padding-left: 10px">
                        Jaminan Kesehatan
                      </p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ priceConvert(detail.bpjs_k_comp) }}</v-col
                    >

                    <v-col cols="4" style="padding-left: 10px">
                      Pinjaman
                    </v-col>
                    <v-col
                      @click="showLoan()"
                      cols="2"
                      class="d-flex justify-end"
                      style="cursor: pointer; color: blue"
                      >{{ priceConvert(detail.piutang) }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4"> </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="4" class="d-flex align-end">
                      <p style="margin: 0; padding-left: 10px; font-size: 11px">
                        COP
                      </p>
                    </v-col>

                    <v-col
                      @click="showCop()"
                      cols="2"
                      class="d-flex justify-end"
                      style="cursor: pointer; color: blue"
                      >{{ priceConvert(detail.cop) }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                  <v-row no-gutters style="font-size: 12px">
                    <v-col cols="4"> </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="4" style="padding-left: 10px">
                      Lain-lain
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      priceConvert(0)
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                style="padding: 3px; border: 2px solid black; border-top: none"
                no-gutters
              >
                <v-col cols="12" style="padding: 0">
                  <v-row
                    no-gutters
                    style="font-size: 13px; font-weight: bolder"
                  >
                    <v-col cols="4">
                      <p style="margin: 0">Total Take Home Pay</p>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                      >{{ getTotalTHP() }}</v-col
                    >
                    <v-col cols="4">Total Potongan</v-col>
                    <v-col cols="2" class="d-flex justify-end">{{
                      getTotalPotongan()
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                style="padding: 3px; border: 2px solid black; border-top: none"
                no-gutters
              >
                <v-col cols="12" style="padding: 0">
                  <v-row
                    no-gutters
                    style="font-size: 13px; font-weight: bolder"
                  >
                    <v-col cols="6">
                      <p style="margin: 0">Total Gaji Yang Dibayarkan</p>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">{{
                      priceConvert(detail.gaji_bersih)
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                style="padding: 3px; border: 2px solid black; border-top: none"
                no-gutters
              >
                <v-col cols="12" style="padding: 0">
                  <v-row
                    no-gutters
                    style="font-size: 13px; font-weight: bolder"
                  >
                    <v-col cols="6">
                      <p style="margin: 0">Total Gaji Periode Lalu</p>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">{{
                      priceConvert(detail.thp_periode_lalu)
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="padding: 3px; margin-top: 30px" no-gutters>
                <v-col
                  cols="12"
                  class="d-flex justify-end"
                  style="padding: 0; font-size: 13px; font-weight: bolder"
                >
                  <p style="margin: 0">Jakarta, 28 Desember 2023</p>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-top: 10px">
                  <v-row
                    no-gutters
                    style="font-size: 13px; font-weight: normal"
                  >
                    <v-col
                      cols="4"
                      class="d-flex justify-end"
                      style="padding-right: 15px"
                    >
                      <p style="margin: 0">Penerima,</p>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-end"
                      >PT SanQua Multi Internasional</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="padding: 3px; margin-top: 80px" no-gutters>
                <v-col
                  cols="4"
                  class="d-flex justify-end"
                  style="
                    padding: 0 15px 0 0;
                    font-size: 13px;
                    font-weight: bolder;
                  "
                >
                  <p style="margin: 0; text-decoration: underline">
                    {{ detail.employee.name }}
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-row
                    no-gutters
                    style="font-size: 13px; font-weight: normal"
                  >
                    <v-col
                      cols="4"
                      class="d-flex justify-end"
                      style="font-style: italic; padding-right: 15px"
                    >
                      <p style="margin: 0">STAFF</p>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-end"
                      >Staff Payroll</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card>

          <v-dialog v-model="loanDialog" width="60%" @click:outside="closeLoan">
            <v-card
              style="padding: 10px 20px 20px 20px; position: relative"
              class="text-center"
            >
              <v-row>
                <v-col md="12" style="padding: 0">
                  <v-card-title>
                    <v-spacer></v-spacer>DETAIL ANGSURAN<v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text style="margin-bottom: 10px">
                    <v-expansion-panels accordion multiple v-model="loanPanel">
                      <v-expansion-panel
                        v-for="(item, index) in loanResult"
                        :key="index"
                      >
                        <v-expansion-panel-header>
                          ANGSURAN ({{ index + 1 }})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table
                            :headers="loanDetailHeaders"
                            :items="item.loan_detail.filter(objectFilter)"
                            :items-per-page="10000"
                            mobile-breakpoint="0"
                            fixed-header
                            hide-default-footer
                          >
                            <template v-slot:[`item.payment_amount`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                Rp{{ priceConvert(item.payment_amount) }}
                              </div>
                            </template>
                            <template v-slot:[`item.remaining_loan`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                Rp{{ priceConvert(item.remaining_loan) }}
                              </div>
                            </template>
                            <template v-slot:[`item.payment_date`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                {{ convertDate(item.payment_date) }}
                              </div>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>

          <v-dialog v-model="copDialog" width="60%" @click:outside="closeCop">
            <v-card
              style="padding: 10px 20px 20px 20px; position: relative"
              class="text-center"
            >
              <v-row>
                <v-col md="12" style="padding: 0">
                  <v-card-title>
                    <v-spacer></v-spacer>DETAIL ANGSURAN COP<v-spacer
                    ></v-spacer>
                  </v-card-title>

                  <v-card-text style="margin-bottom: 10px">
                    <v-expansion-panels accordion multiple v-model="copPanel">
                      <v-expansion-panel
                        v-for="(item, index) in copResult"
                        :key="index"
                      >
                        <v-expansion-panel-header>
                          ANGSURAN ({{ index + 1 }})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table
                            :headers="copDetailHeaders"
                            :items="item.cop_detail.filter(objectFilter)"
                            :items-per-page="10000"
                            mobile-breakpoint="0"
                            fixed-header
                            hide-default-footer
                          >
                            <template v-slot:[`item.payment_amount`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                Rp{{ priceConvert(item.payment_amount) }}
                              </div>
                            </template>
                            <template v-slot:[`item.remaining_cop`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                Rp{{ priceConvert(item.remaining_cop) }}
                              </div>
                            </template>
                            <template v-slot:[`item.payment_date`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                {{ convertDate(item.payment_date) }}
                              </div>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="overtimeDialog"
            width="90%"
            @click:outside="closeOvertime"
          >
            <v-card
              style="padding: 10px 20px 20px 20px; position: relative"
              class="text-center"
            >
              <v-row>
                <v-col md="12" style="padding: 0">
                  <v-card-title
                    ><v-spacer></v-spacer>LIST PENGAJUAN LEMBUR<v-spacer
                    ></v-spacer>
                  </v-card-title>
                  <v-data-table
                    mobile-breakpoint="0"
                    fixed-header
                    height="50vh"
                    :headers="overtimeHeaders"
                    style="cursor: pointer"
                    @click:row="showOvertime"
                    :items="overtimeResult"
                    item-key="id"
                    :footer-props="{
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                    }"
                  >
                    <template v-slot:[`item.request_no`]="{ item }">
                      <div
                        :style="`width:120px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.request_no }}
                      </div>
                    </template>
                    <template v-slot:[`item.total_hour`]="{ item }">
                      <div
                        style="
                          width: 70px;
                          font-size: 14px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                          justify-content: start;
                          align-items: center;
                        "
                      >
                        {{ item.total_hour + ' ' }}jam
                      </div>
                    </template>
                    <template v-slot:[`item.total_hour_rounding`]="{ item }">
                      <div
                        style="
                          width: 70px;
                          font-size: 14px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                          justify-content: start;
                          align-items: center;
                        "
                      >
                        {{ item.total_hour_rounding + ' ' }}jam
                      </div>
                    </template>
                    <template v-slot:[`item.request_start_time`]="{ item }">
                      <div
                        style="
                          width: 150px;
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ convertDateTime(item.request_start_time) }}
                      </div>
                    </template>
                    <template v-slot:[`item.request_end_time`]="{ item }">
                      <div
                        style="
                          width: 150px;
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ convertDateTime(item.request_end_time) }}
                      </div>
                    </template>
                    <template v-slot:[`item.status_request`]="{ item }">
                      <div
                        :style="`text-transform:uppercase; font-size:12px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                          item.status_request
                        )};`"
                      >
                        {{ statusName(item.status_request) }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="absenceDialog"
            max-width="350px"
            @click:outside="closeAbsence"
          >
            <v-card
              style="padding: 10px 20px 20px 20px; position: relative"
              class="text-center"
            >
              <v-row v-if="!isLoading">
                <v-col md="12" style="padding: 0">
                  <v-card-title
                    ><v-spacer></v-spacer>DETAIL POTONGAN ABSENSI<v-spacer
                    ></v-spacer>
                  </v-card-title>

                  <v-card-text
                    v-if="
                      attendanceSummary.length == 0 ||
                      attendanceSummary.find(
                        ({ attendance_status }) =>
                          attendance_status === 'alpha' ||
                          attendance_status === 'late'
                      ) === undefined
                    "
                    class="d-flex justify-center align-center"
                    style="height: 40vh; margin-top: 10px"
                  >
                    <p style="padding: 0">TIDAK DITEMUKAN</p>
                  </v-card-text>

                  <v-card-text v-else style="margin-top: 10px">
                    <v-row
                      v-for="(item, index) in attendanceSummary"
                      :key="index"
                    >
                      <v-col
                        v-if="
                          item.attendance_status == 'alpha' ||
                          item.attendance_status == 'late'
                        "
                        cols="12"
                      >
                        <div
                          class="d-flex flex-row align-center justify-center"
                        >
                          <div
                            class="d-flex justify-content-between align-center"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              width: 100px;
                            "
                          >
                            <p
                              :style="`padding: 0; margin: 0; color: ${
                                item.attendance_status == 'alpha' ||
                                item.attendance_status == 'late'
                                  ? 'red'
                                  : 'black'
                              };`"
                            >
                              {{ indoLabel(item.attendance_status) }}
                            </p>
                            <p
                              :style="`margin-left: auto; padding: 0; margin: 0; color: ${
                                item.attendance_status == 'alpha' ||
                                item.attendance_status == 'late'
                                  ? 'red'
                                  : 'black'
                              };`"
                            >
                              :
                            </p>
                          </div>
                          <div
                            v-if="
                              item.total_late_minutes === null ||
                              item.attendance_status === 'alpha'
                            "
                            style="margin-left: 10px"
                          >
                            <v-text-field
                              :value="item.frequency + ' kali'"
                              dense
                              style="height: 40px"
                            />
                          </div>
                          <div
                            v-else
                            style="margin-left: 10px"
                            class="d-flex justify-center align-center"
                          >
                            <v-text-field
                              :value="convertedDecimal(item)"
                              dense
                              style="height: 40px"
                            />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-progress-circular
                v-else
                :size="50"
                :width="10"
                color="blue"
                indeterminate
                >Loading...</v-progress-circular
              >
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="pph21Dialog"
            max-width="1200px"
            scrollable
            @click:outside="closePph21Dialog"
          >
            <!-- <v-card style="position: relative">
              <v-btn
                icon
                @click="closePph21Dialog"
                style="position: absolute; top: 10px; right: 25px; z-index: 99"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-text> -->
            <Pph21Template
              :detail="pph21Detail"
              @closePph21Dialog="closePph21Dialog"
            />
            <!-- </v-card-text>
            </v-card> -->
          </v-dialog>
        </v-container>
      </div>
    </div>
    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import Pph21Template from './Pph21Template.vue'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: { Pph21Template },
  data() {
    return {
      config: require('../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      attendance: buildType.apiURL('attendanceTrial'),
      upload: buildType.apiURL('upload2'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoading: false,
      isLoadingPage: true,
      loading: false,
      rDialog: false,
      loanDialog: false,
      copDialog: false,
      overtimeDialog: false,
      absenceDialog: false,
      pph21Dialog: false,
      search: null,
      detail: null,
      pph21Detail: null,
      componentItems: [],
      form: {
        header: {
          act: 'add',
          effective_date: '2024-01-05',
          component_name: 'Tunjangan Jabatan',
          type: 'Adjustment',
          description: ''
        },
        component: {
          employee: null,
          current_amount: 0,
          new_amount: 0
        }
      },
      wWidth: window.innerWidth,
      rules: {
        typeRules: []
      },
      dropdown: {
        type: [],
        component: []
      },
      employeeDetails: null,

      overtimeHeaders: [
        {
          text: 'No. Pengajuan',
          value: 'request_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Waktu mulai',
          value: 'request_start_time',
          sortable: false
        },
        {
          text: 'Waktu berakhir',
          value: 'request_end_time',
          sortable: false
        },
        {
          text: 'Total waktu lembur',
          value: 'total_hour',
          sortable: false
        },
        {
          text: 'Pembulatan',
          value: 'total_hour_rounding',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status_request',
          sortable: false
        }
      ],
      overtimeResult: [],
      loanResult: [],
      copResult: [],
      loanDetail: [],
      attendanceSummary: [],
      loanPanel: [],
      copPanel: [],
      loanHeaders: [
        {
          text: 'Jumlah Pinjaman',
          value: 'amount',
          sortable: false
        },
        {
          text: 'Cicilan',
          value: 'installment',
          sortable: false
        },
        {
          text: 'Tgl. Berlaku',
          value: 'effective_date',
          align: 'left',
          sortable: false
        }
      ],
      loanDetailHeaders: [
        {
          text: 'Cicilan',
          value: 'installment_seq',
          sortable: false
        },
        {
          text: 'Jumlah dibayarkan',
          value: 'payment_amount',
          sortable: false
        },
        {
          text: 'Sisa hutang',
          value: 'remaining_loan',
          sortable: false
        },
        {
          text: 'Tgl. pembayaran',
          value: 'payment_date',
          sortable: false
        }
      ],
      copDetailHeaders: [
        {
          text: 'Cicilan',
          value: 'installment_seq',
          sortable: false
        },
        {
          text: 'Jumlah dibayarkan',
          value: 'payment_amount',
          sortable: false
        },
        {
          text: 'Sisa cop',
          value: 'remaining_cop',
          sortable: false
        },
        {
          text: 'Tgl. pembayaran',
          value: 'payment_date',
          sortable: false
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getDropdownPlant',
      'getLeaveDetail',
      'getLoginPayroll'
    ])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator
    if (this.getLoginPayroll) {
      this.verifyAccessToken()
    } else {
      setTimeout(() => {
        this.$router.push('/hr/payroll')
      }, 100)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    // this.$refs.entryForm.reset()
  },
  watch: {
    search(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.items = []
      }
    }
  },
  methods: {
    ...mapActions(['dropdownPlant', 'menu_access', 'getMenuId']),
    ...mapMutations(['setLoginPayroll']),
    async verifyAccessToken() {
      this.isLoadingPage = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code === '00') {
            this.getDataMenu()
          } else {
            this.setLoginPayroll(null)
            setTimeout(() => {
              this.$router.push('/hr/payroll')
            }, 100)
          }

          this.isLoadingPage = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      this.form.name = this.getUserProfile.employee.name
      this.form.company_id = Number(
        this.getUserProfile.employee.company.plant_id
      )
      this.form.department_id = this.getUserProfile.employee.department_id
      this.form.position_id = this.getUserProfile.employee.level_id

      this.getDetail()
    },
    getTotalTHP() {
      let total = 0
      const gajiPokok = this.detail.gaji_pokok
      const rapel = this.detail.rapel !== null ? this.detail.rapel : 0
      const lembur = this.detail.lembur
      const lainLain = this.detail.lain_lain
      const compBPJSTK = this.detail.bpjs_tk_comp
      const compBPJSK = this.detail.bpjs_k_comp
      const compBPJSjp = this.detail.bpjs_jp_comp
      const tunjab = this.detail.tunjangan_jabatan
      const tunperum = this.detail.tunjangan_perumahan
      const tunpetan = this.detail.tunjangan_penempatan
      const bonus = this.detail.bonus !== null ? this.detail.bonus : 0
      const thr = this.detail.thr
      total =
        gajiPokok +
        rapel +
        lembur +
        lainLain +
        compBPJSTK +
        compBPJSK +
        compBPJSjp +
        tunjab +
        tunperum +
        tunpetan +
        thr +
        bonus
      return this.priceConvert(total)
    },
    getTotalPotongan() {
      let total = 0
      const compBPJSTK = this.detail.bpjs_tk_comp
      const compBPJSK = this.detail.bpjs_k_comp
      const compBPJSjp = this.detail.bpjs_jp_comp
      const empBPJSTK = this.detail.bpjs_tk_emp
      const empBPJSK = this.detail.bpjs_k_emp
      const empBPJSjp = this.detail.bpjs_jp_emp
      const absensi = this.detail.potongan_absensi
      const piutang = this.detail.piutang
      const cop = this.detail.cop
      const lain_lain = this.detail.lain_lain
      total =
        compBPJSTK +
        compBPJSK +
        compBPJSjp +
        empBPJSTK +
        empBPJSK +
        empBPJSjp +
        absensi +
        piutang +
        cop +
        lain_lain
      return this.priceConvert(total)
    },
    async getDetail() {
      this.loading = true
      let url = ''
      url = `${this.hrsApi}employee/payroll/detail/${this.$route.params.id}`
      await axios
        .get(url, {
          headers: {
            'x-access-token': this.getLoginPayroll
          }
        })
        .then((res) => {
          console.log('Detaill >>>', res)
          if (res.data.status_code !== '-99') {
            // res.data.data.
            this.detail = res.data.data
            this.getListOvertime()
            this.getListLoan()
            this.getListCop()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    async getListOvertime() {
      const url = `${this.hrsApi}employee/overtime_request/list?offset=0&limit=1000&order_by=id&order_type=asc&mode=3&employee_id=${this.detail.employee_id}`
      // if (this.detail.company !== null) {
      //   url = url + `&company_id=${this.detail.company.id}`
      // }
      axios
        .get(url)
        .then((res) => {
          console.log('list overtime >>>>', res)
          this.overtimeResult = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getListLoan() {
      this.loanPanel = []
      const url = `${this.hrsApi}employee/loan/list?offset=0&limit=10000&mode=3&employee_id=${this.detail.employee_id}`
      // if (this.detail.company !== null) {
      //   url = url + `&company_id=${this.detail.company.id}`
      // }
      axios
        .get(url)
        .then((res) => {
          console.log('loan list>>>', res)
          if (res.data.status_code === '00') {
            this.loanResult = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              this.loanPanel.push(i)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getListCop() {
      this.copPanel = []
      const url = `${this.hrsApi}employee/cop/list?offset=0&limit=10000&mode=3&employee_id=${this.detail.employee_id}`
      axios
        .get(url)
        .then((res) => {
          console.log('cop list>>>', res)
          if (res.data.status_code === '00') {
            this.copResult = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              this.copPanel.push(i)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getSummaryAttendance() {
      this.isLoading = true
      const url = `${this.attendance}get_summary_attendance?nik=${this.detail.employee.nik}&start_date=${this.detail.period_start_date}&end_date=${this.detail.period_end_date}`
      axios
        .get(url)
        .then((res) => {
          console.log('summary absensi>>>', res)
          if (res.data.status_code === '00') {
            this.attendanceSummary = res.data.data
          }
          this.isLoading = false
          return null
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
          return null
        })
    },
    addItem() {
      setTimeout(() => {
        this.rDialog = true
      }, 100)
    },
    editItem(pItem) {},
    deleteItem(pitem) {
      this.componentItems.pop()
    },
    saveItem() {
      console.log(this.form)
      const form = {
        id: Math.random() * 100,
        employee: this.form.component.employee,
        current_amount: this.form.component.current_amount,
        new_amount: this.form.component.new_amount
      }
      // if (form !== null) {
      this.componentItems.push(form)
      setTimeout(() => {
        // this.showMsgDialog('success', 'Save Success', false)
        this.rDialog = false
      }, 300)
      // }
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    querySelections(v) {
      axios
        .get(`${this.hrsApi}employee/dropdown_on_leave?keyword=${v}`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.items = res.data.data)
          }
          return (this.items = [])
        })
        .catch((err) => {
          this.items = []
          return console.log(err)
        })
    },
    clearForm(val) {
      // reset error validation
      // this.$refs.entryForm.resetValidation()

      // // reset form
      this.$refs.entryForm.reset()

      this.rules.descriptionRules = []
      this.rules.typeRules = []
      this.rules.categoryRules = []
      this.rules.totalRequestedRules = []
      this.rules.delegationRules = []
      this.rules.photoRules = []

      for (let i = 0; i < this.form.request_leave_date.length; i++) {
        this.form.request_leave_date[i].startRules = []
        this.form.request_leave_date[i].endRules = []
      }
    },
    submitComponent() {
      const self = this
      // this.rules.typeRules = [v => !!v || 'Leave type is required']

      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      this.showMsgDialog('success', 'Submit success', false).then(
        (response) => {
          setTimeout(() => {
            this.$router.push('/hr/payroll/detail/12321312')
          }, 500)
        }
      )
      // const form = {
      //   act: this.form.header.act
      // }

      // console.log(form)
      // this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/leave/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.clearForm()
            this.showMsgDialog('success', res.data.status_msg, false).then(
              (response) => {
                setTimeout(() => {
                  this.$router.push(
                    `/hr/leave/detail/${
                      this.form.act === 'add'
                        ? res.data.created_id
                        : this.form.id
                    }`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    close() {
      // this.$refs.entryForm.reset()
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/hr/payroll/generate')
      }
    },

    // async getDetailEmployee(val) {
    //   axios
    //     .get(`${this.hrsApi}employee/detail/${val}`)
    //     .then(res => {
    //       console.log(res)
    //       if (res.data.status_code === '00') {
    //         this.employeeDetails = res.data.data
    //       }
    //       return null
    //     })
    //     .catch(err => {
    //       return console.log(err)
    //     })
    // },
    showOvertime() {
      setTimeout(() => {
        this.overtimeDialog = true
      }, 300)
    },
    showCop() {
      setTimeout(() => {
        this.copDialog = true
      }, 300)
    },
    showLoan() {
      setTimeout(() => {
        this.loanDialog = true
      }, 300)
    },
    async showAbsence() {
      this.isLoading = true
      this.getSummaryAttendance()
      setTimeout(() => {
        this.absenceDialog = true
      }, 300)
    },
    showPph21() {
      this.pph21Detail = this.detail
      setTimeout(() => {
        this.pph21Dialog = true
      }, 300)
    },
    objectFilter(item) {
      const itemDate = new Date(item.payment_date)
      const slipDate = new Date(this.detail.period_end_date)
      // const today = new Date()
      return itemDate <= slipDate
    },
    closeOvertime() {
      setTimeout(() => {
        this.overtimeDialog = false
      }, 300)
    },
    closeCop() {
      setTimeout(() => {
        this.copDialog = false
        this.copDetail = []
      }, 300)
    },
    closeLoan() {
      setTimeout(() => {
        this.loanDialog = false
        this.loanDetail = []
      }, 300)
    },
    closeAbsence() {
      setTimeout(() => {
        this.absenceDialog = false
        setTimeout(() => {
          this.attendanceSummary = []
        }, 500)
      }, 300)
    },
    closePph21Dialog() {
      setTimeout(() => {
        this.pph21Dialog = false
      }, 300)
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      } else {
        return '-'
      }
    },
    convertDateTime(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    convertedDecimal(item) {
      let val = ''
      if (item !== null) {
        if (item.total_late_minutes !== undefined) {
          console.log('convertedDecimal>>>>', item.total_late_minutes)
          console.log('late minutes>>>>', item.total_late_minutes.length)
          if (`${item.total_late_minutes}`.length < 4) {
            val = item.total_late_minutes
          } else {
            val = item.total_late_minutes.toFixed(2)
          }
        }
      }
      return val + ' menit'
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MAI'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    },

    getCompanyName(val) {
      let name = ''
      if (val != null) {
        switch (val) {
          case 6:
            name = 'PT SANQUA MULTI INTERNASIONAL'
            break

          default:
            break
        }
      }

      return name
    },

    statusName(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'APPROVED'
        default:
          break
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        default:
          break
      }
    },
    indoLabel(val) {
      switch (val) {
        case 'normal':
          return 'NORMAL'
        case 'alpha':
          return 'ALPHA'
        case 'late':
          return 'TERLAMBAT'
        case 'off':
          return 'LIBUR'
        case 'is_permit':
          return 'IZIN'
        case 'is_leave':
          return 'CUTI'
        default:
          return '-'
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    }
  }
}
</script>
